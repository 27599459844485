import { createModule, mutation, action, extractVuexModule } from "vuex-class-component";


const VuexModule = createModule({
    namespaced: "ui",
    strict: false,
})

export class UIStore extends VuexModule {
    public sidebarShow: string | boolean = 'responsive';
    public sidebarMinimize: boolean = false;
    public asideShow: boolean = false;
    public darkMode: boolean = false;
    public registerState: number = 1;
    public dragging:boolean = false;

    private readonly version = '0.1'

    @mutation setDragging(state: boolean){
        this.dragging=state;
    }

    @mutation toggleSidebarDesktop(state: any) {
        const sidebarOpened = [true, 'responsive'].includes(state.sidebarShow)
        this.sidebarShow = sidebarOpened ? false : 'responsive'
    }

    @mutation toggleSidebarMobile(state: any) {
        const sidebarClosed = [false, 'responsive'].includes(state.sidebarShow)
        this.sidebarShow = sidebarClosed ? true : 'responsive'
    }

    @mutation toggleAside() {
        this.asideShow=!this.asideShow;
    }

    @mutation openAside() {
        this.asideShow=true;
    }

    @mutation toggleDarkMode() {
        this.darkMode=!this.darkMode;
    }

    get getAsideState(){
        return this.asideShow;
    }

    get RegisterState():number{
        return this.registerState;
    }

    set RegisterState(which:number){
        this.registerState=which;
    }

    @mutation nextRegisterState(){
        this.registerState+=1;
        if (this.registerState>3){
            this.registerState=1;
        }
    }

}
