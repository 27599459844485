

import {RegisterDTO} from "@shared_vue/types/RegisterDTO";
import Axios, { AxiosResponse } from 'axios';
import {RegisterOTPDTO} from "@shared_vue/types/RegisterOTPDTO";
import {SetPasswordDTO} from "@shared_vue/types/SetPasswordDTO";
import firebase from "firebase";
import {PrimaryCellData} from "@shared_vue/types/PrimaryCellData";




export class UserApi {
    private static apiInstance: UserApi;
    private static base = process.env.VUE_APP_USER_API_URL;


    public static getInstance(): UserApi {
        if (!UserApi.apiInstance) {
            UserApi.apiInstance = new UserApi();
        }

        return UserApi.apiInstance;
    }

    public static b64DecodeUnicode(str: string) {
        return decodeURIComponent(atob(str).replace(/(.)/g, function (m, p) {
            var code = p.charCodeAt(0).toString(16).toUpperCase();
            if (code.length < 2) {
                code = '0' + code;
            }
            return '%' + code;
        }));
    }

    public static decode(str:string) {
        let output = str.replace(/-/g, "+").replace(/_/g, "/");
        switch (output.length % 4) {
            case 0:
                break;
            case 2:
                output += "==";
                break;
            case 3:
                output += "=";
                break;
            default:
                throw "Illegal base64url string!";
        }

        try {
            return UserApi.b64DecodeUnicode(output);
        } catch (err) {
            return atob(output);
        }
    }

    public static jwtDecode(jwt: string): any{




        let jwtArray = jwt.split('.');

        return {
            header: UserApi.decode(jwtArray[0]),
            payload: UserApi.decode(jwtArray[1]),
            signature: UserApi.decode(jwtArray[2])
        };
    }

    //NB type response too
    //response is just 200 with user-id which is a string (GUID)
    // public async registerUser(body: RegisterDTO) {
    //     return Axios.post<string>(`/user/register`, body)
    // }

   

    public async loginNoRedirect(userName: string, password: string): Promise<firebase.auth.UserCredential>{

        return await firebase
            .auth()
            .signInWithEmailAndPassword(userName, password);
    }

    public async loginCustomToken(token: string): Promise<firebase.auth.UserCredential>{

        return await firebase
            .auth()
            .signInWithCustomToken(token);
    }

}


