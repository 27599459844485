import Vue from 'vue'
import App from './App.vue'
import router from './router'
import CoreuiVue from '@coreui/vue/src';
import {iconsSet as icons} from './assets/icons/icons'
import {store} from "@/store";
import {worker} from "@/mocks/browser";
import * as firebase from "firebase";
import 'firebase/auth';
import 'firebase/firestore'
import {vxm} from "@/store";
import axios from 'axios';
import VueFilterDateFormat from '@vuejs-community/vue-filter-date-format';
import VueFilterDateParse from '@vuejs-community/vue-filter-date-parse';
import visibility from 'vue-visibility-change';
import {UserManagerApiFactory} from "@shared_vue/openapi/userapi"
import VueCookies from 'vue-cookies';
import install from '@shared_vue/components/placesautocomplete/index';


Vue.use(VueCookies)
// registry directive
Vue.use(visibility);
Vue.use(VueFilterDateParse);
Vue.use(VueFilterDateFormat);
Vue.use(CoreuiVue);
Vue.use(install);

// visibility.change((evt, hidden) => {
//   console.log('hidden:'+hidden)
//   if (hidden){
//     let userApi = UserManagerApiFactory(undefined,'http://localhost:5002');
//     try {
//       userApi.userManagerInvalidateMFA().then(resp=>{
//         console.log(resp.status)
//       }).catch(err=>{
//         console.log(err)
//         if (err.response.status==403) {
//           if (err.response.headers.location!==undefined)
//           {
//             //redirect
//             if (err.response.headers.location==="/nowhereville") {
//               store.commit('auth/requireBiometric', true)
//             }
//           }
//         }
//       });
//
//     } catch (e){
//       // just do nothing for now. I'll give this a thing
//     }
//   }
// });
Vue.config.productionTip = false



// if (process.env.NODE_ENV === 'development') {
//   worker.start();
// }

const configOptions = {
  apiKey: "AIzaSyCu1pdi3fAksEqyjXCtBpgRfrAEk-mOnYg",
  authDomain: "trackbox-world.firebaseapp.com",
  databaseURL: "https://trackbox-world.firebaseio.com",
  projectId: "trackbox-world",
  storageBucket: "trackbox-world.appspot.com",
  messagingSenderId: "781096390213",
  appId: "1:781096390213:web:1daf5f647a6ee3d782dd2c",
  measurementId: "G-YJGMTFR2M5"
};

firebase.initializeApp(configOptions);
// const db = firebase.firestore();
firebase.auth().onAuthStateChanged(user => {
  if (user) {
    console.log(user); // It shows the Firebase user
    console.log('setup firestore')
    // db.collection("users").doc(user.uid)
    //     .onSnapshot((doc) => {
    //       console.log("user firestore update: ", doc.data());
    //       // @ts-ignore
    //       store.commit('auth/requireBiometric', doc.data().requiresbio)
    //     });
    user.getIdToken().then(function(idToken) {  // <------ Check this line
      console.log(idToken); // It shows the Firebase token now
      console.log('got a user: ' + user);
      store.commit('auth/setUser',user);
      store.commit('auth/setToken',idToken);
      // vxm.auth.setUser(user);
      // vxm.auth.setToken(idToken);
      axios.defaults.headers.common = {'Authorization': `Bearer ${idToken}`}
    });
  }
});

firebase.auth().onIdTokenChanged(user=> {
  if (user){
    user.getIdToken().then(function(idToken) {  // <------ Check this line
      console.log(idToken); // It shows the Firebase token now
      console.log('got a user: ' + user);
      store.commit('auth/setUser',user);
      store.commit('auth/setToken',idToken);
      // vxm.auth.setUser(user);
      // vxm.auth.setToken(idToken);
      axios.defaults.headers.common = {'Authorization': `Bearer ${idToken}`}
    });
  }
})
new Vue({
  router,
  store,
  //CIcon component documentation: https://coreui.io/vue/docs/components/icon
  icons,
  render: h => h(App),
}).$mount('#app')
