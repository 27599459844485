import { createModule, mutation, action, extractVuexModule } from "vuex-class-component";



import {LoginState} from "@/store/auth/types";
import {UserApi} from "@shared_vue/services/userservice";
import {AxiosResponse} from "axios";

import firebase from "firebase";
import {LoginDTO} from "@shared_vue/types/LoginDTO";

const VuexModule = createModule({
  namespaced: "auth",
  strict: false,
})

export class AuthStore extends VuexModule {


    public loginState: LoginState = LoginState.LoggedOut;
    public usr: firebase.User|null=null;
    public idToken: string ='';
    public bioRequired: boolean = false;


    @mutation setUser(val:firebase.User){
        this.usr=val;
    }

    @mutation requireBiometric(val:boolean){
        this.bioRequired=val;
    }

    @mutation setToken(val:string){
        this.idToken=val;
    }

    get User() {
        return this.usr;
    }



    get LoggedIn(){
        return (this.usr!=null);
    }

    @action async loginNoRedirect(loginDto: LoginDTO) {
        let result = UserApi.getInstance().loginNoRedirect(loginDto.username, loginDto.password);
        return await result;
    }



    public signInWithCustomToken(token:string){
        firebase.auth().signInWithCustomToken(token).catch(function(error) {
            // Handle Errors here.
            var errorCode = error.code;
            var errorMessage = error.message;
            // ...
        });
    }

    @action
    async signout(){
        firebase.auth().signOut().then(function() {
            // Sign-out successful.
        }).catch(function(error) {
            // An error happened.
        });
    }


    get currentLoginState(): LoginState {
        return this.loginState;
    }

    get UserId(): string | undefined {
        return this.usr?.uid;
    }

    get Email(): string | null | undefined {
        return this.usr?.email;
    }

    get DisplayName(): string | null | undefined {
        return this.usr?.displayName;
    }

    get Token(): string | null | undefined {
        return this.idToken;
    }

}
