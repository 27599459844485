<template>
    <div class="profile-card">
        <div>
            <slot name="body">
                This content is in card body component.
            </slot>
        </div>
        <p>
            <slot name="description">
                <small>{{description}}</small>
            </slot>
        </p>
    </div>

</template>

<script>
    export default {
        name: "ProfileCard",
        props: {
            description: {
                type: String,
                default: ''
            },
          alias: {
              type: String,
            default: ''
          }
        }
    }
</script>
