import { createModule, mutation, action, extractVuexModule } from "vuex-class-component";
import { StackCase } from "@shared_vue/types/StackCase";
import {CaseFilterDTO} from "@shared_vue/openapi/caseapi/api";


const VuexModule = createModule({
    namespaced: "case",
    strict: false,
})

export class CaseStore extends VuexModule {
    public caseStack: StackCase[] = []
    public caseFilter: CaseFilterDTO = CaseStore.getDefaultCaseFilter();

    private readonly version = '0.1'
    
    public static getDefaultCaseFilter(): CaseFilterDTO {
        let cf = <CaseFilterDTO>{};
        cf.caseStates = [];
        cf.caseTypes = [];
        let rn = new Date();
        let monthAgo = new Date();
        monthAgo.setMonth(monthAgo.getMonth() -1);
        cf.startDate = monthAgo.toJSON();
        cf.endDate =  rn.toJSON();
        return cf;
    }

    @mutation setStack(cases: StackCase[]){
        this.caseStack=cases;
    }

    @mutation addCase(newCase: StackCase){
        this.caseStack.push(newCase);
    }

    @mutation resetFilter(){
        this.caseFilter = <CaseFilterDTO>{};
    }
    
    @mutation toggleVisible(state: string){
        if (!this.caseFilter.caseStates)
            this.caseFilter.caseStates = new Array<string>();
        let index = this.caseFilter.caseStates.indexOf(state);
        if (index > -1){
            //remove
            this.caseFilter.caseStates.splice(index,1);
        } else {
            this.caseFilter.caseStates.push(state);
        }
    }
    
    get currentFiler(){
        return this.caseFilter;
    }
    
    @mutation updateFilter(newFilter: CaseFilterDTO){
        this.caseFilter = newFilter;
    }

    // @action async fetchFreshCaseStack(){
    //     try {
    //         let results = await CaseApi.getInstance().getCases();
    //         if (results && results.status == 200) {
    //             this.setStack(results.data);
    //         }
    //     } catch (e) {
    //         //hmm
    //     }
    // }




}
