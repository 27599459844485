<template>
    <CButton block color="primary" size="sm">
        <slot></slot>
    </CButton>
</template>

<script>
    export default {
        name: "FormButton"
    }
</script>

<style scoped>
    button {
        padding: 0;
        width:fit-content;
    }

</style>
