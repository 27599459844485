import Vue from 'vue'
import Router from 'vue-router'
import ProfilePage from "@/views/pages/ProfilePage.vue";



const DashContainer = () => import('../containers/DashContainer.vue');
const TheContainer = () => import('../containers/TheContainer.vue');
const Home = () => import('../views/Home.vue');
const Dashboard = () => import('../views/Dashboard.vue');
const CaseStack = () => import('@/views/pages/CaseStack.vue');
const LiveCase = () => import('@/views/pages/LiveCase.vue')
const ExistingCase = () => import('@/views/pages/ExistingCase.vue')
const CaseManagement = () => import('@/views/pages/CaseManagement.vue')
const EmergencyCase = () => import('@/views/pages/EmergencyCase.vue')
const CallPage = () => import('@/views/pages/CallPage.vue')
Vue.use(Router);

const router = new Router({
  mode: 'history', // https://router.vuejs.org/api/#mode
  linkActiveClass: 'open active',
  routes: [
    {
      path: '/',
      component: TheContainer,
      children: [
        {
          path: '',
          name: 'Home',
          component: Home
        }
      ]
    },
    {
      path: '/dashboard',
      component: DashContainer,
      children: [
        {
          path: '',
          name: 'Dashboard',
          component: Dashboard
        },
        {
          path: 'cases',
          name: 'Case Stack',
          component: CaseStack
        },
        {
          path: 'call',
          name: 'Call Test',
          component: CallPage
        },
        {
          path: 'livecase',
          name: 'Live Case',
          component: LiveCase
        },
        {
          path: 'casemanagement',
          name: 'Case Management',
          component: CaseManagement
        },
        {
          path: 'casemanagement/:caseid',
          name: 'Case Management',
          component: CaseManagement,
          props: ({ params }) => ({ caseid: params.caseid })
        },
        {
          path: 'casecapture',
          name: 'Case Capture',
          component: ExistingCase
        },
        {
          path: 'user/:guid',
          name: 'User',
          component: ProfilePage,
          props: ({ params }) => ({ guid: params.guid })
        },
        {
          path: 'casecapture/:caseid',
          name: 'Existing Case',
          component: ExistingCase,
          props: ({ params }) => ({ caseid: params.caseid })
        },
        {
          path: 'emergencycase',
          name: 'Emergency Dispatch',
          component: EmergencyCase
        },

      ]
    }
  ]
})

export default router
