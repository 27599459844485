import Vue from 'vue';
import Vuex, { StoreOptions } from 'vuex';
import {createProxy, extractVuexModule} from "vuex-class-component/js";
import {UIStore} from "@/store/ui";
import {AuthStore} from "@/store/auth";
import VuexPersistence from 'vuex-persist'

Vue.use(Vuex);

import localForage from "localforage";
import {CaseStore} from "@/store/case";


//this is what you crave: https://github.com/localForage/localForage
const storeKey = 'imicsspastorekey';


//case stack could get real big. Do we really want it in the store? I am not sure will see in real-world example
const vuexPersist = new VuexPersistence<any>({
  storage: window.localStorage,
  key: storeKey,
  modules:["ui","case"]
})

export const store = new Vuex.Store({
  modules: {
    ...extractVuexModule( UIStore ),
    ...extractVuexModule( AuthStore ),
    ...extractVuexModule( CaseStore ),
  },
  plugins: [vuexPersist.plugin]
})

export const vxm = {
  ui: createProxy( store, UIStore ),
  auth: createProxy(store, AuthStore),
  case: createProxy(store, CaseStore),
}
